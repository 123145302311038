const $ = require('jquery');
export default () => {
    let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //let headerH = $('.nav').height();
    $('a[href^="#"]').on('click',function() {
        // header size
        //let headerH = $('.header').height();

        // スクロールの速度
        let speed = 500; // ミリ秒
        // アンカーの値取得
        let href= $(this).attr("href");
        // 移動先を取得
        let target = $(href == "#" || href === "" ? 'html' : href);
        // 移動先を数値で取得
        //let position =  (windowWidth > 768)? target.offset().top - headerH : target.offset().top;
        let position =  target.offset().top;
        // スムーススクロール
        $('body,html').animate({scrollTop:position}, speed,'swing');
        return false;
    });

    $(window).scroll(()=>{
        let footerH = (windowWidth > 768)? $('.l-footer').offset().top : $('.l-footer').offset().top+20;
        let sclVal= $(window).height() + $(window).scrollTop();
        console.log(`footerH=${footerH}、sclVal=${sclVal}`)
        if ($(window).scrollTop() > 200) {
            $('.backTop').fadeIn();
        } else {
            $('.backTop').fadeOut();
        }
        // TOPへ戻る フッター手前で止まる
        if (sclVal >= footerH) {
            $('.backTop').addClass('stop');
        } else {
            $('.backTop').removeClass('stop');
        }
    })
}

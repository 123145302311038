import $ from 'jquery';
import {
    TweenMax
} from "gsap";
import {
    TimelineMax
} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

export default () => {
    //kv_txt
    const kv_txt = new TimelineMax();
    kv_txt.to('.kv__txtInner', 3.0, {
        opacity: 1,
    }, '=1.0', );


    //ipad
    const ipad = new TimelineMax();
    if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
        ipad.to('.kv__txtInner', 3.0, {
                opacity: 1,
            }, '+=1.0')
            .to('.main__ttlInner', 2.0, {
                opacity: 1,
                top: '50%',
                ease: Power3.easeOut,
            }, '-=2.0')
            .to('.pdt__conBox--01', 2.0, {
                opacity: 1,
                left: '0',
                ease: Power3.easeOut,
            }, '-=1.0')
            .to('.pdt__conImg--01', 2.0, {
                opacity: 1,
                right: 0,
                ease: Power3.easeOut,
            }, '-=2.0')
    }
    //main_ttl
    const main_ttl = new TimelineMax();
    if (window.matchMedia("(max-width: 767px)").matches) {
        main_ttl.to('.main__ttlInner', 2.0, {
            opacity: 1,
            top: '0',
            ease: Power3.easeOut,
        })
        new ScrollMagic.Scene({
                triggerElement: '.main__ttl',
                duration: 0,
                offset: 0,
                reverse: false,
            })
            .setTween(main_ttl)
            //            .addIndicators({
            //                name: "main_ttl"
            //            })
            .addTo(controller);
    } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
        main_ttl.to('.main__ttlInner', 2.0, {
            //            opacity: 1,
            //            top: '50%',
            //            ease: Power3.easeOut,
        })
        new ScrollMagic.Scene({
                triggerElement: '.main__ttl',
                duration: 0,
                offset: 500,
                reverse: false,
            })
            .setTween(main_ttl)
            //            .addIndicators({
            //                name: "main_ttl"
            //            })
            .addTo(controller);
    } else {
        main_ttl.to('.main__ttlInner', 2.0, {
            opacity: 1,
            top: '50%',
            ease: Power3.easeOut,
        })
        new ScrollMagic.Scene({
                triggerElement: '.main__ttl',
                duration: 0,
                offset: 0,
                reverse: false,
            })
            .setTween(main_ttl)
            //            .addIndicators({
            //                name: "main_ttl"
            //            })
            .addTo(controller);
    }




    //product 1
    const product01 = new TimelineMax();
    if (window.matchMedia("(max-width: 767px)").matches) {
        product01.to('.pdt__conBox--01', 2.0, {
                opacity: 1,
                left: '0',
                ease: Power3.easeOut,
            })
            .to('.pdt__conImg--01', 2.0, {
                opacity: 1,
                right: 0,
                ease: Power3.easeOut,
            }, '-=2.0')
    } else {
        product01.to('.pdt__conBox--01', 2.0, {
                opacity: 1,
                left: 0,
                ease: Power3.easeOut,
            })
            .to('.pdt__conImg--01', 2.0, {
                opacity: 1,
                left: 0,
                ease: Power3.easeOut,
            }, '-=2.0')
    }


    new ScrollMagic.Scene({
            triggerElement: '.pdt--01',
            duration: 0,
            offset: -50,
            reverse: false,
        })
        .setTween(product01)
        //                .addIndicators({
        //                    name: "product01"
        //                })
        .addTo(controller);

    //product 2
    const product02 = new TimelineMax();
    if (window.matchMedia("(max-width: 767px)").matches) {
        product02.to('.pdt__conImg--02', 2.0, {
                opacity: 1,
                left: 0,
                ease: Power3.easeOut,
            })
            .to('.pdt__conBox--02', 2.0, {
                opacity: 1,
                right: 0,
                ease: Power3.easeOut,
            }, '-=2.0')
        new ScrollMagic.Scene({
                triggerElement: '.pdt--02',
                duration: 0,
                offset: -100,
                reverse: false,
            })
            .setTween(product02)
//            .addIndicators({
//                name: "product02"
//            })
            .addTo(controller);

    } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
        product02.to('.pdt__conImg--02', 2.0, {
                opacity: 1,
                left: 0,
                ease: Power3.easeOut,
            })
            .to('.pdt__conBox--02', 2.0, {
                opacity: 1,
                right: 0,
                ease: Power3.easeOut,
            }, '-=2.0')
        new ScrollMagic.Scene({
                triggerElement: '.pdt--02',
                duration: 0,
                offset: -300,
                reverse: false,
            })
            .setTween(product02)
//            .addIndicators({
//                name: "product02"
//            })
            .addTo(controller);
    } else {
        product02.to('.pdt__conBox--02', 2.0, {
                opacity: 1,
                right: 0,
                ease: Power3.easeOut,
            })
            .to('.pdt__conImg--02', 2.0, {
                opacity: 1,
                left: 0,
                ease: Power3.easeOut,
            }, '-=2.0')
        new ScrollMagic.Scene({
                triggerElement: '.pdt--02',
                duration: 0,
                offset: -100,
                reverse: false,
            })
            .setTween(product02)
//            .addIndicators({
//                name: "product02"
//            })
            .addTo(controller);
    }
        //product 3
        const product03 = new TimelineMax();
        if (window.matchMedia("(max-width: 767px)").matches) {
            product03.to('.pdt__conBox--03', 2.0, {
                    opacity: 1,
                    left: 0,
                    ease: Power3.easeOut,
                })
                .to('.pdt__conImg--03', 2.0, {
                    opacity: 1,
                    right: 0,
                    ease: Power3.easeOut,
                }, '-=2.0')
        } else {
            product03.to('.pdt__conBox--03', 2.0, {
                    opacity: 1,
                    left: 0,
                    ease: Power3.easeOut,
                })
                .to('.pdt__conImg--03', 2.0, {
                    opacity: 1,
                    right: 0,
                    ease: Power3.easeOut,
                }, '-=2.0')
        }
        new ScrollMagic.Scene({
                triggerElement: '.pdt--03',
                duration: 0,
                offset: -50,
                reverse: false,
            })
            .setTween(product03)
            //        .addIndicators({
            //            name: "product03"
            //        })
            .addTo(controller);
    }

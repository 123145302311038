/*import*/
import $ from 'jquery';
//import Particles from './modules/particles.min';
import ChangeImg from './modules/changeImg';
import Smoothscroll from './modules/smoothscroll';
import Anime from './modules/Anime';
import Polygon from './modules/polygon';
/*create instance*/
/*load event*/
document.addEventListener('DOMContentLoaded', () => {
    ChangeImg();
    Smoothscroll();
    Anime();
//    Particles();
    Polygon();
})

//$(window).on('load',function(){
//  var path = location.pathname
//  if (path == "/news/"){
//    if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
//        $('.news').addClass('h75');
//    }
//  }
//});
const $ = require("jquery");

export default () => {
    let changeIMG;
    let timer = false;
    const $rp = $('img.rp');
    $rp.hide();
    changeIMG = function(){
        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const $body = $('body');
        if(windowWidth <= 767) {
            $body.removeClass('w-sp w-pc');
            $body.addClass('w-sp');
            $rp.each(function() {
                if($(this).attr("src").indexOf('--sp') == -1){
                    $(this).attr("src", $(this).attr("src").replace(/(\.)(png|jpg|gif)/, "--sp$1$2"));
                }
                $(this).show();
            });
        }else {
            $body.removeClass('w-sp w-pc');
            $body.addClass('w-pc');
            $rp.each(function() {
                $(this).attr("src", $(this).attr("src").replace("--sp", ""));
                $(this).show();
            });
        }
    };
    changeIMG();
    $(window).on('resize',function(){
        if (timer !== false) {
            clearTimeout(timer);
        }
        timer = setTimeout(function() {
            changeIMG();
        }, 200);
    });
};
